<script>
import Layout from "../../layouts/horizontal";
import http from '@/oauth-client'
//import blockchainHttp from '@/blockchain-http'

import { debounce } from "debounce"
import moment from 'moment'
import Highcharts from "highcharts";
import Swal from "sweetalert2";
import OptimizationView from "@/views/pages/indices/optimization-view";
import { parseErrors } from '../../../helpers'
import IndexSelector from './index-selector.vue'
import { uuid } from 'vue-uuid';
import { toBlob } from 'html-to-image';
import { mapState } from 'vuex';
import {
   authComputed
} from "@/state/helpers";
import { saveAs } from 'file-saver';
import Multiselect from "vue-multiselect";

const signalR = require("@microsoft/signalr");

const colors = [
'#3366CC',
'#DC3912',
'#FF9900',
'#109618',
'#990099',
'#3B3EAC',
'#0099C6',
'#DD4477',
'#66AA00',
'#B82E2E',
'#316395',
'#994499',
'#22AA99',
'#AAAA11',
'#6633CC',
'#E67300',
'#8B0707',
'#329262',
'#5574A6',
'#3B3EAC'
];
//const colors = ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1', '#8B0707','#329262','#5574A6','#3B3EAC'];

export default {
  components: {
    Layout,
  
    OptimizationView,
    IndexSelector,
    Multiselect
  },
  data() {
    return {
      users: [],
      showSuscriptionEventsModal: false,
      subscriptionEvents: [],
      subscribing: false,
      subscribed: null,
      editIndicesOfOptimizations: false,
      cloning: false,
      portfolioSolutions: [],
      selectedPortfolioSolution: null,
      optimizationHistory: [],
      selectedOptimizationHistory: null,
      optimization: {
        walkForwardOptimizationEnabled: false,
        walkForwardOptimization: {
          inSamplePeriod:0,
          periodBetweenRebalancing: 0
        },
        targetVolatilityEnabled: false,
        targetVolatility: 0,
        startDate: null,
        endDate: null,
        solution: {
          values: {
          }
        }
      },

      tags: [],
      allTags: [],

      editOptimizationMode: false,
      savingOptimization: false,

      bookmarks:{},
      mainChartYAxisLogarithmicType: false,
      deleting: false,
      chartOptions: {
        series: [
          {
            type: 'line',
            data: [1, 2, 3]
          },
          
        ]
      },
      weightsPiaChartOptions: null,
      mostRecentWeightsChartOptions: null,
      volatilityScalingFactorChartOptions: null,
      last3yearsCorrelationChartOptions: null,
      allHistoryCorrelationChartOptions: null,
      crisesChartOptions: null,

      monthlyPerf: null,
      monthlyPerfRowNames:[],
      monthlyPerfColumnNames:[],

      showSuscriptionModal: false,
      suscriptionData:{
        name: '',
        amount: 0,
        currency: 'USD'
      },
      inputParamtersFormId: null,
      inputParametersFormOutsideClickConfig: {
        handler: this.hideInputParamtersForm,
       // middleware: this.middleware,
        events: ["click"],
      },

      secondColor: "#6183e8",

      item: null,
      details: null,
      loading: false,
      isStatReady: false,
      normalisedPerformance: false,

      title: "",
      errors:[],
      breadcrumbs: [
      ],
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "YTD"
      ],
      pdfProcessing: false,

      equityMetaData: [],
      equityMetaDataCheckBoxes: {},
      last3YearCorrelationChartDataTable: [],
      allHistoryCorrelationChartDataTable: []
    };
  },
  props: {
    id: {
      type: String,
      default: 'new',
    }
  },
 
  beforeDestroy(){
    if( this.connection){
       this.connection.stop();
       this.connection = null;
    }
  },
  created() {
    this.loading = true;
    const uri = process.env.VUE_APP_BASE_URL + "/client-notifications";
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(uri, { 
        accessTokenFactory: () => localStorage.getItem('access_token')
      })
      .withAutomaticReconnect()
      .build();

    this.connection.on("RefreshIndex", indexId => {
     
      if(this.id === indexId) {
        this.debounceReload();
      }
    });

    this.connection.onreconnecting(error => {
        console.log('signlaR onreconnecting connectionid: ' + this.connection.connectionId, error);
      });

    this.connection.onreconnected(() => {
        console.log('signlaR onreconnected connectionid: ' + this.connection.connectionId);
      });

    this.connection.start()
        .then(() => {
          this.init();
          console.log('signlaR connected! connectionid: ' + this.connection.connectionId)
      })
      .catch(console.error);
    this.loadBookmarks();
  },
  watch: {
    mainChartYAxisLogarithmicType:{
      handler: function () {
        this.rebuildNetAssetValueGrowthCharts();
      }
    },
    normalisedPerformance: {
      handler: function () {
        this.load();
      }
    }, 
    connectionId: {
      handler: function () {
        this.loadSubscriptions();
      }
    },
    assetClassSolutionParameters(newVal) {
      const assetClassItem = this.equityMetaData.find(item => item.name === 'assetClass');
      if (assetClassItem) {
        assetClassItem.solutionParameters = newVal;
      }
    },
    regionSolutionParameters(newVal) {
      const regionItem = this.equityMetaData.find(item => item.name === 'region');
      if (regionItem) {
        regionItem.solutionParameters = newVal;
      }
    },
    fundamentalsSolutionParameters(newVal) {
      const fundamentalsItem = this.equityMetaData.find(item => item.name === 'fundamentals');
      if (fundamentalsItem) {
        fundamentalsItem.solutionParameters = newVal;
      }
    },
    sectorSolutionParameters(newVal) {
      const sectorItem = this.equityMetaData.find(item => item.name === 'sector');
      if (sectorItem) {
        sectorItem.solutionParameters = newVal;
      }
    },
    equityMetaDataCheckBoxes: {
      deep: true,
      handler() {
        for (const subClass of this.equityMetaData) {
          this.calculateSubClassCheckbox(subClass);
        }
      }
    }
  },
  computed: {
    ...authComputed,
    ...mapState('signalr', {
       connectionId: 'connectionId'
    }),
    isAdmin() {
      return this.currentUser.isAdmin;
    },
    isC8Internal() {
      return this.currentUser.isC8Internal;
    },
    toggleIndexComposition: {
      get() {
        return this.item.composition.find(x => x.chartVisible !== true) ? false : true;
      },
      set(v) {
        if(v) {
          this.item.composition.forEach(x => x.chartVisible = true);
          this.rebuildNetAssetValueGrowthCharts();
        } else {
          this.item.composition.forEach(x => x.chartVisible = false);
          this.rebuildNetAssetValueGrowthCharts();
        }
      }
    },
    checkedEditedComposition: {
        get() {
        return this.item.editedComposition.find(x => x.checked !== true) ? false : true;
      },
      set(v) {
        if(v) {
          this.item.editedComposition.forEach(x => x.checked = true);
        } else {
          this.item.editedComposition.forEach(x => x.checked = false);
        }
        this.$forceUpdate();
      }
    },
    assetClassSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('AssetClass'));
      } else {
        return [];
      }
    },
    regionSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('Region'));
      } else {
        return [];
      }
    },
    fundamentalsSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('Fundamentals'));
      } else {
        return [];
      }
    },
    sectorSolutionParameters() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter(x => x.name.startsWith('SectorL1'));
      } else {
        return [];
      }
    },
    solutionParametersNoEquityMetaData() {
      if(this.selectedPortfolioSolution) {
        return this.selectedPortfolioSolution.solutionParameters.filter((param) =>
          param.scope === 'PerSolution' &&
          !this.assetClassSolutionParameters.includes(param) && !this.regionSolutionParameters.includes(param) &&
          !this.fundamentalsSolutionParameters.includes(param) && !this.sectorSolutionParameters.includes(param)
        ) || [];
      } else {
        return [];
      }
    }
  },
  methods: {
    addTag(t) {
      this.allTags = [...(this.allTags || []), t];
      this.tags = [...(this.tags || []), t];
    },
    getUserName(userId) {
      const u = this.users.find(x => x.Id === userId);
      return u?.Name ?? '';
    },
    debounceReload: debounce(function () {
      this.load();
      this.loadOptimizationHistory();
      window.setTimeout(this.load, 5000);
    }, 1500),
    toggleInputParamtersForm(id) {
      
      if(this.inputParamtersFormId === id) {
        this.inputParamtersFormId = null;
      } else {
        this.inputParamtersFormId = id;
      }
    },
    copyParameterToSelected(index, indexId, p) {
      const key = p.id + indexId;
      const v = this.optimization.solution.values[key];
      for(let i = 0; i < this.item.editedComposition.length; i++) {
        if(i != index && this.item.editedComposition[i].checked === true) {
          const childId = this.item.editedComposition[i].childId;
          const newKey = p.id + childId; 
          this.optimization.solution.values[newKey] = v;
        }
      }
      this.$forceUpdate();
      this.hideInputParamtersForm();
    },
    copyParameterToAll(index, indexId, p) {
      const key = p.id + indexId;
      const v = this.optimization.solution.values[key];
      for(let i = 0; i < this.item.editedComposition.length; i++) {
        if(i != index) {
          const childId = this.item.editedComposition[i].childId;
          const newKey = p.id + childId; 
          this.optimization.solution.values[newKey] = v;
        }
      }
      this.$forceUpdate();
      this.hideInputParamtersForm();
    },
    hideInputParamtersForm() {
      this.inputParamtersFormId = null;
    },
    async loadSubscriptionEvents() {
      const response = await http.get('api/index-subscription/' + this.item.id);
      this.subscriptionEvents = response.data;
    },
    async loadSubscriptions() {
      if(this.connectionId && !this.subscriptionsLoaded) {
        const req = { 
          id: uuid.v1(),
          clientId: this.connectionId,
          type: 'LOAD_ALL_SUBSCRIPTIONS',
          payload: null,
          userName: this.$store.state.auth.currentUser.username,
          userId: this.$store.state.auth.currentUser.id,
        };
      
        try {
          const response = await http.post('api/backend/invoke', req);
          const payload = response.data.Payload;
          console.log('loadSubscriptions response', {response, payload});
          this.subscribed = payload.Subscriptions.find(id => id === this.id) ? true : false;
        } catch(e) {
          console.error(e);
        } 
        this.subscriptionsLoaded = true;
      }
      
    },
    async subscribe() {
      const payload = {
        indexId: this.item.id,
        ...this.suscriptionData
      };
      const req = { 
        id: uuid.v1(),
        clientId: this.$store.state.signalr.connectionId,
        type: 'NEW_INDEX_SUBSCRIPTION',
        payload: payload,
        userName: this.$store.state.auth.currentUser.username,
        userId: this.$store.state.auth.currentUser.id,
      };
      this.subscribing = true;
      try {
        await http.post('api/backend/invoke', req);
        this.subscribed = true;
        await http.post('api/index-subscription', {indexId: this.item.id});
        await this.loadSubscriptionEvents();
      } catch(e) {
        console.error(e);
      } finally {
        this.subscribing = false;
        this.showSuscriptionModal = false;
      }
    },
    async onSubscribe() {

      this.suscriptionData = {
        amount: 0,
        currency: 'USD'
      };
      
      this.showSuscriptionModal = true;
     
    },
    getTooltipForLast3yearsCorrelation(x, y, v) {
      return `<div>${y}</div><div>${x}</div><div><b>${v}</b></div>`;
    },
    async clone() {
      const swalResult = await Swal.fire({
        title: 'Index name',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Ok'
      })
      if(swalResult.isConfirmed && swalResult.value) {
        const opt = [...this.item.composition];
        const solutionId = this.item.optimization.solutionId;
        const portfolioSolution =  this.portfolioSolutions.find(x => x.id === solutionId);

        const dto = {
          name: swalResult.value,
          displayname: swalResult.value,
          description: this.item.description,
          isComposed: true,
          publicIndex: this.item.publicIndex,
          children: this.item.composition.map(a => a.childId),
          optimization: {
             walkForwardOptimizationEnabled: this.optimization.walkForwardOptimizationEnabled,
             walkForwardOptimization: {
               inSamplePeriod: (this.optimization.walkForwardOptimization.inSamplePeriod || 0).toFixed(),
               periodBetweenRebalancing:  (this.optimization.walkForwardOptimization.periodBetweenRebalancing || 0).toFixed()
             },
             targetVolatilityEnabled: this.optimization.targetVolatilityEnabled,
             targetVolatility: this.optimization.targetVolatility,
             solution: portfolioSolution.id,
          
             startDate: this.optimization.startDate ? moment(this.optimization.startDate, 'DD/MM/YYYY').toDate() : null,
             endDate: this.optimization.endDate ? moment(this.optimization.endDate, 'DD/MM/YYYY').toDate() : null,
             shouldBeRecalculated: true,

             parameters:[],
           }
        };
      
        opt.forEach(i => {
          portfolioSolution.positionParameters.forEach(p =>{
              if(p.type === 'Index') {
                dto.optimization.parameters.push({ 
                  solutionParameter: p.id, index: i.childId, 
                  stringValue: this.optimization.solution.values[p.id + i.childId] 
                });
              } else {
                dto.optimization.parameters.push({ 
                  solutionParameter: p.id, index: i.childId, 
                  value: this.optimization.solution.values[p.id + i.childId] 
                });
              }
            });
          }
        );
        portfolioSolution.solutionParameters.forEach(p =>{
          if(p.type === 'Index') {
            dto.optimization.optimization.push({ solutionParameter: p.id, stringValue: this.optimization.solution.values[p.id] });
          } else {
            dto.optimization.parameters.push({ solutionParameter: p.id, value: this.optimization.solution.values[p.id] });
          }
        });

        this.cloning = true;
        try {
          const resp = await http.post('api/indices', dto);
          this.$router.push({ name: "index-view", params: { id: resp.data.id } });
        } finally {
          this.cloning = false;
        }
       
      }  
    },
    showEditIndicesOfOptimizations() {
      this.editIndicesOfOptimizations = true;
    },
    paramIsNumberInput(param){
      return param.name === 'percentile' || param.name === 'explainedVariance';
    },
    paramIsPercentageNumberInput(param){
      return param.type !== 'Index' && param.name !== 'MVOLambda' && param.name !== 'percentile' && param.name !== 'explainedVariance';
    },
    formatPercentageInput(v) {
      if(!v) {
        v = 0;
      } else {
        if(v.endsWith('.')) return v;
        v = parseFloat(parseFloat(v.toString()).toFixed(1));
      }
      return v;
    },
    removeEditedIndex(i) {
      this.item.editedComposition = this.item.editedComposition.filter(x => x != i);
      
    },
    formatToPercentage(v) {
      
      const res = parseFloat((v*100).toFixed(1));
     
      return isNaN(res) ? null : res;
    },
    setValueFromPercentage(id, v) {
      if(!v) {
        v = 0;
      } else {
        v = parseFloat(parseFloat(v.toString()).toFixed(1));
      }
      const o = JSON.parse(JSON.stringify(this.optimization));
      o.solution.values[id] = parseFloat((v/100).toFixed(3));
      this.optimization = o;
      console.log("setValueFromPercentage 2", this.optimization.solution.values[id]);
    },
    portfolioSolutionChanged (){
      if(this.selectedPortfolioSolution.walkForwardOptimizationEnabled) {
        this.optimization.walkForwardOptimizationEnabled = true;
        this.optimization.walkForwardOptimization = {
          inSamplePeriod: this.selectedPortfolioSolution.walkForwardOptimization.inSamplePeriod,
          periodBetweenRebalancing: this.selectedPortfolioSolution.walkForwardOptimization.periodBetweenRebalancing
        }
      } 

      if(this.selectedPortfolioSolution.targetVolatilityEnabled) {
        this.optimization.targetVolatilityEnabled = this.selectedPortfolioSolution.targetVolatilityEnabled;
        this.optimization.targetVolatility = this.selectedPortfolioSolution.targetVolatility; 
      }

      if(this.selectedPortfolioSolution.startDate){
         this.optimization.startDate = this.selectedPortfolioSolution.startDate;
      }

      if(this.selectedPortfolioSolution.endDate){
         this.optimization.endDate = this.selectedPortfolioSolution.endDate;
      }
    },
    async submitOptimization () {
      const childrenIndexIDs = this.item.editedComposition.map(x => x.childId);
      if(!childrenIndexIDs || childrenIndexIDs.length <= 0) {
        Swal.fire({
          title: this.$t("common.cannot-save"),
          text: this.$t("pages.indexView.index-list-cannot-be-empty"),
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("common.ok")
        });
        return;
      }
     
      const dto = {
        walkForwardOptimizationEnabled: this.optimization.walkForwardOptimizationEnabled,
        walkForwardOptimization: {
          inSamplePeriod: (this.optimization.walkForwardOptimization.inSamplePeriod || 0).toFixed(),
          periodBetweenRebalancing:  (this.optimization.walkForwardOptimization.periodBetweenRebalancing || 0).toFixed()
        },
        targetVolatilityEnabled: this.optimization.targetVolatilityEnabled,
        targetVolatility: this.optimization.targetVolatility,
        solution: this.selectedPortfolioSolution.id,
        parameters:[],
        startDate: this.optimization.startDate,
        endDate: this.optimization.endDate,
        shouldBeRecalculated: true,
        children: this.item.editedComposition.map(x => x.childId),
        tags: this.tags || [],
      };
      this.item.editedComposition.forEach(i => {
          this.selectedPortfolioSolution.positionParameters.forEach(p =>{
            if(p.type === 'Index') {
              dto.parameters.push({ 
                solutionParameter: p.id, index: i.childId, 
                stringValue: this.optimization.solution.values[p.id + i.childId] 
              });
            } else {
              dto.parameters.push({ 
                solutionParameter: p.id, index: i.childId, 
                value: this.optimization.solution.values[p.id + i.childId] 
              });
            }
            
          });
        }
      );
      this.selectedPortfolioSolution.solutionParameters.forEach(p =>{
        const paramValue = this.optimization.solution.values[p.id];
        const parameterObject = {
          solutionParameter: p.id,
          [p.type === 'Index' ? 'stringValue' : 'value']: paramValue,
        };

        if (this.solutionParametersNoEquityMetaData.includes(p)) { // p is not EquityMetaData
          dto.parameters.push(parameterObject);
        } else {
          if (paramValue !== null && this.equityMetaDataCheckBoxes[p.title]) { // p is EquityMetaData and has value and row checkbox selected
            dto.parameters.push(parameterObject);
          }
        }
      });

      this.savingOptimization = true;
      await http.post('api/indices/' + this.item.id + '/optimizations', dto);
      this.savingOptimization = false;
      this.disableEditOptimizationMode();
      this.load();
    },
    async loadPortfolioSolutions() {
      const resp = await http.get('api/portfolio-solutions');
      this.portfolioSolutions = resp.data;

      this.equityMetaData = [
        {
          name: "assetClass",
          title: "Asset Class",
          solutionParameters: this.assetClassSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        },
        {
          name: "region",
          title: "Region",
          solutionParameters: this.regionSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        },
        {
          name: "fundamentals",
          title: "Fundamentals",
          solutionParameters: this.fundamentalsSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        },
        {
          name: "sector",
          title: "Sector",
          solutionParameters: this.sectorSolutionParameters,
          expanded: false,
          allSelected: false,
          indeterminate: false
        }
      ];
    },
    enableEditOptimizationMode() {
      this.editOptimizationMode = true;
      this.editIndicesOfOptimizations = false;
      this.item.editedComposition = [...this.item.composition.map(x => ({...x, checked: true}))];
    },
    disableEditOptimizationMode() {
      this.editOptimizationMode = false;
      this.editIndicesOfOptimizations = false;
      this.item.editedComposition = [];
    },
    async loadBookmarks() {
       const resp = await http.get( 'api/indices/bookmarks');
       this.bookmarks = resp.data.reduce((obj,item)=> (obj[item.indexId] = true, obj),{});
    },
    async addBookmark() {
      let o = {...this.bookmarks};
      o[this.item.id] = true;
      this.bookmarks = o;
     
      await http.post('api/indices/' + this.item.id + '/bookmarks');
    },
    async removeBookmark(){
      let o = {...this.bookmarks};
      o[this.item.id] = false;
      this.bookmarks = o;
      await http.delete('api/indices/' + this.item.id + '/bookmarks');
    },
    onDelete() {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.deleting = true;
      http.delete(`api/indices/${this.item.id}`).then(() => {
        Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
        this.deleting = false;
        this.openIndicesPage();
      }).catch(error => {
        this.deleting = false;
        this.errors = parseErrors(error);
        Swal.fire({
          title: 'Unable to delete',
          text: this.errors[0],
          icon: "warning",
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: 'Ok'
        });
      });
    },
    openIndicesPage() {
      this.$router.push({ name: "home" });
    },
    getOptimizationParametersPerPosition() {
      const a = this.item.optimization.parameters.filter(x => x.scope !== 'PerSolution').map(x => x.solutionParameterId);
      const b = [...new Set(a)];
      const c = b.map(x => this.item.optimization.parameters.find(e => e.solutionParameterId === x));
      return c;
    },
    async init() {
      this.loading = true;
      await this.loadPortfolioSolutions();
      await this.load();
      await this.loadOptimizationHistory();
      await this.loadSubscriptionEvents();
      await this.loadSubscriptions();
    
      this.loading = false;
    },
    async loadUsers()  {
     let p = {
       $search: null,
       $top: 10000,
       $skip: 0
     };
     const resp = await http.get(`data/User/objects`, {
       params: p
     });
     const users = [...resp.data.Items];
     this.users = users;
   },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
     
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then(() => {
       this.pdfProcessing = false;
        //let totalPages = pdf.internal.getNumberOfPages();

         //pdf.deletePage(1);
       // console.log('totalPages: ', totalPages)
        // if(!this.item.isComposed) {
        //   if(totalPages > 4) {
        //   //   pdf.deletePage(0);
        //     pdf.deletePage(1);
        //   }
        //   totalPages = pdf.internal.getNumberOfPages();
        // }else {
        //   // if(totalPages > 5) {
        //   // //   pdf.deletePage(0);
        //   //   pdf.deletePage(1);
        //   // }
        //   // totalPages = pdf.internal.getNumberOfPages();
        // }
        
      //  console.log('totalPages 2: ', totalPages)
        // for (let i = 1; i <= totalPages; i++) {
        //     pdf.setPage(i-1)
        //     pdf.setFontSize(10)
        //     pdf.setTextColor(150)
        //     pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        // } 
      }).save();
      //this.pdfProcessing = false;
    },
    onProgress($event) {
      console.log('onProgress');
      console.log($event)
    },
    hasStartedGeneration() {
     console.log('hasStartedGeneration');
    },
    generateReport () {
     
       this.pdfProcessing = true;
       window.setTimeout(()=>this.$refs.html2Pdf.generatePdf(), 500);
    },
    async generateReportV2 () {
     
      this.pdfProcessing = true;
    
      const formData = new FormData();
      const stats = this.item.statistic;
      const data = {
        header: this.item.name + '(' + moment(this.item.updatedAt).format("ddd, MMM Do YYYY") + ')',
        stats,
        optimization: this.item.optimization
      };

      if(this.item.childrenIndexStatistic) {
        const indexComposition = [];
        this.item.composition.forEach(cIndex => {
          const values = (this.item.childrenIndexStatistic[cIndex.childId] || {}).values || {};
          const o = {
            name: cIndex.childName,
            ...values
          };
          indexComposition.push(o);
        });
        data.indexComposition = indexComposition;
      }

       if(this.mostRecentWeightsChartOptions && this.item.compositionWeights) {
        data.CompositionWeights = this.item.compositionWeights;
       }

      if(this.monthlyPerfRowNames) {
        const monthlyPerformance = [];
        this.monthlyPerfRowNames.forEach(rName => {
          const o = {
            year: rName
          };
          this.monthNames.filter(x => x !== 'YTD').forEach(cName => {
            o[cName] = {
              base: ((this.monthlyPerf[rName][cName] || {}).base || '').replace('%', ''),
              additional:(this.monthlyPerf[rName][cName] || {}).additional
            };
          });
          o['Ytd'] = {
            base: ((this.monthlyPerf[rName]['YTD'] || {}).base|| '').replace('%', ''),
            additional:(this.monthlyPerf[rName]['YTD'] || {}).additional
          };
          monthlyPerformance.push(o);
        });
        data.monthlyPerformance = monthlyPerformance;
        data.monthlyPerformanceHint= this.item.monthlyPerformanceHint;
      }
    
      console.log('data', data);
      formData.append('json', JSON.stringify(data));

      function generateFileName(baseName) {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        
       
        const dateString = `${year}-${month}-${day} ${hours}${minutes}`;
        
        return `${baseName} – ${dateString}.pdf`;
      }


      let send = async () => {
        console.log('send ', formData);
        const response = await http({
          method: 'post',
          url:  `api/indices/${this.id}/export/pdf`,
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
          responseType: 'blob',
        });
        this.pdfProcessing = false;
       
        let fileName = generateFileName(this.item.name);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
            window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                    { type: 'application/pdf' }
                ),
                fileName
            );
        } else {
            const url = window.URL.createObjectURL(new Blob([response.data],
                { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',fileName);
            document.body.appendChild(link);
            link.click();
        }
      }

      let images = [];
      const imageHandler = async (blob, img) => {
        images = images.filter(x => x !== img);
        formData.append(img.name, blob, img.name);
        if(images.length === 0) {
          send();
        } else {
          const ni = images[0];
          this.createImage(ni, imageHandler);
        }
      };
     
      images.push({el: this.$refs.chart.$el, name: 'mainchart.png'}); 
      if(this.$refs.weightsPiaChart?.$el) {
        images.push({el: this.$refs.weightsPiaChart.$el, name: 'weights.png'});
      }
      if(this.$refs.volatilityScalingFactorChart?.$el) {
        images.push({el: this.$refs.volatilityScalingFactorChart.$el, name: 'volatilityScalingFactor.png'});
      }
      if(this.$refs.mostRecentWeightsChart?.$el) {
        images.push({el: this.$refs.mostRecentWeightsChart.$el, name: 'mostRecentWeightsChart.png'});
      }
      if(this.$refs.last3yearsCorrelationChart?.$el) {
        images.push({el: this.$refs.last3yearsCorrelationChart.$el, name: 'last3yearsCorrelationChart.png'});
      }
      if(this.$refs.allHistoryCorrelationChart?.$el) {
        images.push({el: this.$refs.allHistoryCorrelationChart.$el, name: 'allHistoryCorrelationChart.png'});
      }
      if(this.$refs.crisesChart?.$el) {
        images.push({el: this.$refs.crisesChart.$el, name: 'crisesChart.png'});
      }
      if(this.$refs.mainImage) {
        images.push({el: this.$refs.mainImage, name: 'mainImage.png'});
      }
      
      if(images.length === 0) {
        send();
      } else {
        const img = images[0];
        this.createImage(img, imageHandler);
      }
    },
    createImage(img, onComplete) {
      console.log('createImage', img );
      toBlob(img.el)
        .then(function (blob) {
          onComplete(blob, img);
        });
    },
    hasGenerated () {
    
    },
    getDailyNetReturnsUrl(){
      return this.item ? (process.env.VUE_APP_BASE_URL + "/api/indices/" + this.item.id + "/netreturns/daily") : '';
    },
    getMonthlyNetReturnsUrl(){
      return  this.item ? (process.env.VUE_APP_BASE_URL + "/api/indices/" + this.item.id + "/netreturns/monthly") : '';
    },
    getImageUrl(){
      return this.item ? (process.env.VUE_APP_BASE_URL + "/api/indices/" + this.item.id + "/image") : "/";
    },
    updateBreadCrumbsAndTitle() {
      this.title =this.item.displayName;
      this.breadcrumbs = [
        {
          text: 'Marketplace',
           to: { name: 'indices' }
        },
        {
          text: this.item.displayName,
          active: true
        }
      ];
    },
    async loadOptimizationHistory () {
      const { data } = await http.get('api/indices/' + this.id + '/optimizations/history');
      data.forEach(x => x.activatedAtFormatted = moment(x.activatedAt).format('LL'));
      this.optimizationHistory = data;
      //this.selectedOptimizationHistory null,
    },
    async load() {
      
      const allTagsResponse =await http.get('api/index-tags');
      this.allTags = allTagsResponse.data;

      let dataStr = localStorage.getItem('index_view_history');
      let indexViewHistory = [];
      if(dataStr) {
        indexViewHistory = JSON.parse(dataStr);
      } else {
        indexViewHistory = [];
      }

      try {
        const { data } = await http.get('api/indices/' + this.id + (this.normalisedPerformance === true ? '?normalized=true' : ''));
        (data.composition || []).forEach(x => {
          if(this.item && this.item.composition && this.item.composition.length > 0){
            const e = this.item.composition.find(u => u.childId === x.childId);
            x.chartVisible = e && e.chartVisible === false ? false : true;
          } else {
            x.chartVisible = true;
          }
        });
        const tagsSystemValue = (data.systemValues || {})['$TAGS$'];
        if(tagsSystemValue) {
          try {
            this.tags = JSON.parse(tagsSystemValue);
          }catch(e) {
            console.error(e)
          }
        }
        this.prepareNetAssetValueGrowthCharts(data);
        this.prepareWeightsDetailsModel(data);
        data.updatedAtFormatted = moment(data.updatedAt).format("LL");
        this.item = {...data, editedComposition: [] };
      
        if(!this.editOptimizationMode && data.optimization) {
         
          this.optimization.startDate = moment(data.optimization.startDate).toDate();
          this.optimization.endDate = moment(data.optimization.endDate).toDate();

          this.optimization.walkForwardOptimizationEnabled = data.optimization.walkForwardOptimizationEnabled || false;
          if(this.optimization.walkForwardOptimizationEnabled) {
            this.optimization.walkForwardOptimization.inSamplePeriod = data.optimization.walkForwardOptimization.inSamplePeriod;
            this.optimization.walkForwardOptimization.periodBetweenRebalancing = data.optimization.walkForwardOptimization.periodBetweenRebalancing;
          }

          this.optimization.targetVolatilityEnabled = data.optimization.targetVolatilityEnabled || false;
          if(this.optimization.targetVolatilityEnabled) {
            this.optimization.targetVolatility = data.optimization.targetVolatility || 0;
          }

          const solutionId = (data.optimization || {}).solutionId || null;
          if(solutionId && this.item.composition && this.item.composition.length > 0) {
            this.selectedPortfolioSolution = this.portfolioSolutions.find(x => x.id === solutionId);
            this.portfolioSolutions.forEach(a => {
              if(a.name === 'Weights') {
                a.positionParameters.forEach(x => {
                  this.item.composition.forEach(p => {
                    const existedParameter = data.optimization.parameters.find(r => r.solutionParameterId === x.id && r.indexId === p.childId);
                    if(x.type !== 'Index') {
                      this.optimization.solution.values[x.id + p.childId] = existedParameter 
                        ? existedParameter.value 
                        : parseFloat((1 / this.item.composition.length).toPrecision(p.decimals));
                    } else {
                      this.optimization.solution.values[x.id + p.childId] = existedParameter 
                        ? existedParameter.stringValue 
                        : null;
                    }
                  });
                });
              } else {
                a.positionParameters.forEach(x => {
                  this.item.composition.forEach(p => {
                    if(x.type !== 'Index') {
                      const existedParameter = data.optimization.parameters.find(r => r.solutionParameterId === x.id && r.indexId === p.childId);
                      this.optimization.solution.values[x.id + p.childId] = existedParameter ? (existedParameter.value || 0) 
                        : x.defaultValue || 0;
                    } else {
                       const existedParameter = data.optimization.parameters.find(r => r.solutionParameterId === x.id && r.indexId === p.childId);
                      this.optimization.solution.values[x.id + p.childId] = existedParameter ? (existedParameter.stringValue || null) 
                        : null;
                    }
                  });
                });
              }
            
              a.solutionParameters.forEach(x => {
                const existedParameter = data.optimization.parameters.find(a => a.solutionParameterId === x.id && !a.indexId);
              
                if(x.type !== 'Index') {
                  this.optimization.solution.values[x.id] = existedParameter ? (existedParameter.value || x.defaultValue || 0) : (x.defaultValue || 0);
                } else {
                  this.optimization.solution.values[x.id] = existedParameter ? (existedParameter.stringValue || null) : null;
                }

                if(existedParameter !== undefined){
                  this.setEquityMetaDataCheckBox(existedParameter);
                }
              });
            });
          }
        }
        this.updateBreadCrumbsAndTitle();
        await this.processStat();

        if(!indexViewHistory.find(a => a.id === data.id)) {
          indexViewHistory.push({ id: data.id, displayName: data.displayName });
        }
      
        if(indexViewHistory.length > 20) {
         
          indexViewHistory = indexViewHistory.slice(1, 21);
         
        }
        const indexViewJson = JSON.stringify(indexViewHistory);
      
        localStorage.setItem('index_view_history', indexViewJson);

        // window.setTimeout(() => {
        //   this.addBlockToBlockChain();
        // }, 3000);
       
        
      } catch(e) {
        console.log(e);
        indexViewHistory = [...indexViewHistory.filter(a => a.id !== this.id)];
        localStorage.setItem('index_view_history', JSON.stringify(indexViewHistory));
        this.openIndicesPage();
      }
      
    },
    generatorColor: function(){
      if(!this.colorIndex) {
        this.colorIndex = 0;
      }
      if(this.colorIndex >= colors.length) {
        this.colorIndex = 0;
      }
      return colors[this.colorIndex++];
    },
    debounceRebuildChart: debounce(function () {
      this.rebuildNetAssetValueGrowthCharts();
    }, 300),
    rebuildNetAssetValueGrowthCharts(){
      if(!this.item)
        return;
          
      let chartSeries =  this.item.netAssetValueGrowthCharts || [];
    

      if(this.item.childrenNetAssetValueGrowthCharts && this.item.childrenNetAssetValueGrowthCharts.length > 0) {
         chartSeries = [...chartSeries,...this.item.childrenNetAssetValueGrowthCharts];
      }
       
      if(this.item.isComposed) {
        chartSeries = chartSeries.filter(a => !this.item.composition.find(x => x.childId === a.indexId) || this.item.composition.find(x => x.childId === a.indexId && x.chartVisible === true));
      }
    
      let seriesOptions = [];
      for(let i = 0; i < chartSeries.length; i++){
        
        const source = chartSeries[i];
        seriesOptions[i] = {
          name: source.title + (source.isLive ? ' [Live]' : ''),
          label: source.title,
          data: (source.points || []).map(x => [x.timeStamp, x.value]),
          color: source.color,
          lineWidth: (source.isLive || (this.item.isComposed && this.item.displayName === source.title)) ? 4 : 1
        };
      }

      this.chartOptions = {
        credits:{
           enabled: false
        },
        legend:{
          enabled: true
        },
        exporting: {
          buttons: {
              contextButton: {
                  menuItems: [
                  "viewFullscreen", 
                  "printChart", 
                  "separator", 
                  "downloadPNG", 
                  "downloadJPEG", 
                  "downloadPDF", 
                  "downloadSVG",
                  "separator",
                  "downloadXLS",
                  "downloadCSV",
                  "viewData" ]
              }
          }
        },
        rangeSelector: {
          buttons: [{
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month'
          }, {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months'
          }, {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months'
          }, {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date'
          }, {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year'
          }, 
          {
              type: 'year',
              count: 2,
              text: '2y',
              title: 'View 2 years'
          }, 
          {
              type: 'year',
              count: 3,
              text: '3y',
              title: 'View 3 years'
          }, 
          {
              type: 'year',
              count: 5,
              text: '5y',
              title: 'View 5 years'
          }, 
          {
              type: 'all',
              text: 'All',
              title: 'View all'
          }]
        },
        xAxis: {
          type: 'datetime',
        
          labels: {
              
          },
        
        },
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        plotOptions: {
          series: {
            showInNavigator: true
          }
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2,
          xDateFormat:  '%d/%m/%Y'
        },
        colors: colors,
        series: seriesOptions
      };
    },
    prepareNetAssetValueGrowthCharts(data) {
      let i = 0;
    
      let chartSeries =  data.netAssetValueGrowthCharts;
      if(!chartSeries)
        return;

      if(data.childrenNetAssetValueGrowthCharts && data.childrenNetAssetValueGrowthCharts.length > 0){
        data.childrenNetAssetValueGrowthCharts
          .filter(x => x.indexId !== data.id)
          .forEach(x => {
            x.color = colors[i++];
            if(i === colors.length) i = 0;
          });
      }

      chartSeries.forEach(x => x.chartVisible = true);
      chartSeries
        .filter(x => x.indexId === data.id && !x.isLive)
        .forEach(x => x.color = "#FE6C2D");
      chartSeries
        .filter(x => x.indexId === data.id && x.isLive)
        .forEach(x => x.color = "#7d096d");
    },
    prepareWeightsDetailsModel(data) {
      data.compositionWeightsObj = {
        rows: (data.compositionWeights || []).map(x => {
          let o = { 
            value: x.date, 
            date: new Date(x.date),
            sum: x.sum,
            sumStr: x.sumStr,
            origValues: x.values,
            values: {

            }
          };
          x.values.forEach(v => {
            o.values[v.indexId] = {
              ...v
            };
          });
          return o;
        })
      };
      data.compositionWeightsObj.rows.sort(function(a,b){return b.date.getTime() - a.date.getTime()});

     
    },
    buildMonthlyPerformanceTable() {
      let mp = {};
      let monthlyPerfRowNames = [...new Set((this.item.monthlyPerformance || []).map(x => x.year.toString()))];
      let monthlyPerfColumnNames = [...new Set((this.item.monthlyPerformance || []).map(x => this.monthNames[x.month-1]))];
      monthlyPerfRowNames.sort((a, b) => a.localeCompare(b));
      monthlyPerfRowNames.reverse();
     
      monthlyPerfRowNames.forEach(rowName =>{
        const cells = this.item.monthlyPerformance.filter(x => x.year.toString() === rowName);
        let a = {};
        mp[rowName] = a;
        monthlyPerfColumnNames.forEach(columnName =>{
          const cellItems = cells.filter(x =>  this.monthNames[x.month-1] === columnName) || [];
          const c8Value = cellItems.find(x => x.name === 'C8' || x.name === (this.item || {}).name) || {};
          const notC8Value = cellItems.find(x => x.name !== 'C8' && x.name !== (this.item || {}).name) || {};
          a[columnName] = {
            base: c8Value.value,
            additional: notC8Value.value
          };
        });
      });
      this.monthlyPerf = mp;
      this.monthlyPerfRowNames = monthlyPerfRowNames;
      this.monthlyPerfColumnNames = monthlyPerfColumnNames;
    },
    buildWeighsChartsAndTable() {

      let indexColors= {};
      if(this.item.compositionWeightsObj.rows.length > 0) {
        const latestYear = this.item.compositionWeightsObj.rows[0];
        // create weights pie chart
        let weightsPiaChartOptions = {
          credits:{
            enabled: false
          },
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: ''
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %' 
              }
            }
          },
          series: [{
            name: 'Brands',
            colorByPoint: true,
            data: latestYear.origValues.map(x => {
              const color = indexColors[x.indexDisplayName] || (indexColors[x.indexDisplayName] = this.generatorColor());
              return {
                name: `${x.indexDisplayName} (${x.valueStr})`,
                y: x.percValue,
                color: color
              };
            })
          }]
        };
        this.weightsPiaChartOptions = weightsPiaChartOptions;  
        //console.log('weightsPiaChartOptions', weightsPiaChartOptions);
        let mostRecentWeightsItems = [];
        let xLabels = [];
        const mostRecentWeightsLast10YearsRows = [];
        let a = [...this.item.compositionWeightsObj.rows];
        a = a.reverse();
        let startIndex = a.length-10;
        if(startIndex < 0)
          startIndex = 0;
        for(let i = startIndex; i <a.length; i++ ){
          mostRecentWeightsLast10YearsRows.push(a[i]);
        }
        
        mostRecentWeightsLast10YearsRows.forEach(row =>{
          const label = moment(row.date).format("DD/MM/YYYY");
          xLabels.push(label);
        });
      
        this.item.composition.forEach(child => {
          let data = [];
          mostRecentWeightsLast10YearsRows.forEach(row =>{
            const indexValue = row.origValues.find(x => x.indexId === child.childId);
            const v = (indexValue || {}).value || 0;
            data.push(v);
          });
          mostRecentWeightsItems.push( { name: child.childDisplayName, data: data, color:  indexColors[child.childDisplayName] || (indexColors[child.childDisplayName] = this.generatorColor())} )
        });
       
       
        this.mostRecentWeightsChartOptions = {
           credits:{
            enabled: false
          },
           chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                categories: xLabels
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Weights'
                }
            },
            tooltip: {
                pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b> ({point.percentage:.1f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'percent'
                },
            },
            series: mostRecentWeightsItems
        };

        //console.log('mostRecentWeightsItems', mostRecentWeightsItems);

        this.volatilityScalingFactorChartOptions = {
           credits:{
            enabled: false
          },
          chart: {
            type: 'solidgauge'
          },
          title: null,
          pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
          },

            exporting: {
                enabled: false
            },

            tooltip: {
                enabled: false
            },

            // the value axis
            yAxis: {
                stops: [
                    [1, '#55BF3B'], // green
                    [5, '#DDDF0D'], // yellow
                    [10, '#DF5353'] // red
                ],
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -130,
                    text: 'Portfolio Leverage'
                },
                labels: {
                    y: 16
                },
                min: 1,
                max: 10
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: -70,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },

            series: [{
               
                data: [parseFloat(this.item.compositionWeightsObj.rows[0].sum.toFixed(2))],
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:25px">{y}</span><br/>' +
                        '</div>'
                },
            }]
        };
      }
    },
    buildLast3yearsCorrelationChart() {
      if(!this.item.last3YearCorrelationChart || !this.item.isComposed)
        return;

      if(this.item.isComposed !== true)
        return;
    
      const xComposedIndices = this.item.last3YearCorrelationChart.xAxis.map(x => x.indexDisplayName);
      const yComposedIndices = this.item.last3YearCorrelationChart.yAxis.map(x => x.indexDisplayName);
      const data = this.item.last3YearCorrelationChart.data.map(x => [x.x-1, x.y-1, x.value]);
      let dataTable = [];
      for(let y = 0; y < yComposedIndices.length; y++) {
        let yData = [];
        for(let x = 0; x < xComposedIndices.length; x++) {
          const _x = x +1;
          const _y = ((xComposedIndices.length - 1) - y) + 1;
          const o = this.item.last3YearCorrelationChart.data.find(a => a.x === (_x) && a.y === (_y));
          yData.push(o?.value);
        }
        dataTable.push(yData);
      }
      this.last3YearCorrelationChartDataTable = dataTable;
      
     
      this.last3yearsCorrelationChartOptions = {
         credits:{
            enabled: false
          },
          
        chart: {
          type: "heatmap",
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1
        },

        title: {
          text: this.$t("pages.indexView.last3yearsCorrelation-subtitle")
        },

        xAxis: {
          categories: xComposedIndices,
          categoriesReverse: xComposedIndices.slice().reverse()
        },

        yAxis: {
          categories: yComposedIndices,
          categoriesReverse: yComposedIndices.slice().reverse(),
          title: null
        },

        colorAxis: {
          min: 0,
          minColor: "#FFFFFF",
          maxColor: Highcharts.getOptions().colors[0]
        },

        legend: {
          align: "right",
          layout: "vertical",
          margin: 0,
          verticalAlign: "top",
          y: 25,
          symbolHeight: 280
        },

        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.series.xAxis.categories[this.point.x] +
              "</b> sold <br><b>" +
              this.point.value +
              "</b> items on <br><b>" +
              this.series.yAxis.categories[this.point.y] +
              "</b>"
            );
          }
        },

        series: [
          {
            borderWidth: 1,
            data: data,
            dataLabels: {
              enabled: true,
              color: "#000000"
            }
          }
        ]
      };
      console.log('buildLast3yearsCorrelationChart');
      this.redrawCorrelationChartCount = 0;
      window.setTimeout(() => {
        this.forceRedrawCorrelationCharts();
      }, 1000);
    },
    forceRedrawCorrelationCharts() {
      this.redrawCorrelationChartCount = (this.redrawCorrelationChartCount || 0) + 1;
      this.last3yearsCorrelationChartOptions = {...this.last3yearsCorrelationChartOptions};
      this.allHistoryCorrelationChartOptions = {...this.allHistoryCorrelationChartOptions};
      if(this.redrawCorrelationChartCount < 10) {
        window.setTimeout(() => {
          this.forceRedrawCorrelationCharts();
        }, 1000);
      }
    },
    buildAllHistoryCorrelationChart() {
      if(!this.item.allHistoryCorrelationChart || !this.item.isComposed)
        return;

      if(this.item.isComposed !== true)
        return;   

      const xComposedIndices = this.item.allHistoryCorrelationChart.xAxis.map(x => x.indexDisplayName);
      const yComposedIndices = this.item.allHistoryCorrelationChart.yAxis.map(x => x.indexDisplayName);
      const data = this.item.allHistoryCorrelationChart.data.map(x => [x.x-1, x.y-1, x.value]);
      let dataTable = [];
      for(let y = 0; y < yComposedIndices.length; y++) {
        let yData = [];
        for(let x = 0; x < xComposedIndices.length; x++) {
          const _x = x +1;
          const _y = ((xComposedIndices.length - 1) - y) + 1;
          const o = this.item.allHistoryCorrelationChart.data.find(a => a.x === _x && a.y === _y);
          yData.push(o?.value);
        }
        dataTable.push(yData);
      }
      this.allHistoryCorrelationChartDataTable = dataTable;

      this.allHistoryCorrelationChartOptions = {
         credits:{
            enabled: false
          },
        chart: {
          type: "heatmap",
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1
        },

        title: {
          text: this.$t("pages.indexView.allHistoryCorrelation-subtitle")
        },

        xAxis: {
          categories: xComposedIndices,
          categoriesReverse: xComposedIndices.slice().reverse()
        },

        yAxis: {
          categories: yComposedIndices,
          categoriesReverse: yComposedIndices.slice().reverse(),
          title: null
        },

        colorAxis: {
          min: 0,
          minColor: "#FFFFFF",
          maxColor: Highcharts.getOptions().colors[0]
        },

        legend: {
          align: "right",
          layout: "vertical",
          margin: 0,
          verticalAlign: "top",
          y: 25,
          symbolHeight: 280
        },

        tooltip: {
          formatter: function() {
            return (
              "<b>" +
              this.series.xAxis.categories[this.point.x] +
              "</b> sold <br><b>" +
              this.point.value +
              "</b> items on <br><b>" +
              this.series.yAxis.categories[this.point.y] +
              "</b>"
            );
          }
        },

        series: [
          {
           
            borderWidth: 1,
            data: data,
            dataLabels: {
              enabled: true,
              color: "#000000"
            }
          }
        ]
      };
    },
    buildCrisesChart() {
      if(!this.item.crisesChart)
        return;

      this.crisesChartOptions = {
         credits:{
            enabled: false
          },
        chart: {
          type: 'column'
        },
        title: {
          text: this.$t("pages.indexView.crises-subtitle")
        },
        xAxis: {
          categories: this.item.crisesChart.events,
          labels: {
            enabled: true
          }
        },
        yAxis: {
          title: {
            text: '%'
          }
        },
        tooltip: {
          valueSuffix: '%'
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{y}%'
            }
          }
        },
        series: this.item.crisesChart.data
      };
    },
    async processStat(){
      
      this.rebuildNetAssetValueGrowthCharts();
      this.buildMonthlyPerformanceTable();
      this.buildWeighsChartsAndTable();
      this.buildLast3yearsCorrelationChart();
      this.buildAllHistoryCorrelationChart();
      this.buildCrisesChart();
  
      this.isStatReady = true;
    },
    addIndices(newItems) {
      console.log('item.composition', this.item.composition);
      console.log('newItems', newItems);
      this.editIndicesOfOptimizations = false;
      newItems = newItems.filter(i => !this.item.composition.find(a => a.childId === i.id));
      newItems = newItems.map(x => ({checked: true, childId: x.id, childDisplayName: x.displayName, childName: x.displayName}))
      this.item.editedComposition = [...this.item.editedComposition, ...newItems];
    },
    getSolutionParameterTooltip(solutionParameterName){
      const cleanedName = solutionParameterName.toLowerCase().split(" ").join("");
      const toolTipDescription = this.$t('pages.indexView.optimization.solutionParameters_' + cleanedName + 'Tooltip');
      return toolTipDescription;
    },
    downloadWeights() {
      if(this.item.compositionWeightsObj?.rows?.length > 0) {
        let text = '';
        text += 'Date' + ',';
        this.item.composition.forEach(cIndex => {
          text += cIndex.childDisplayName + ',';
        });
        text += 'VolatilityScalingFactor' + '\n';
        this.item.compositionWeightsObj.rows.forEach(row => {
          text += moment(row.value).format("DD/MM/YYYY") + ',';
          this.item.composition.forEach(cIndex => {
            text += (((row.values[cIndex.childId] || {}).value || 0).toFixed(4)) + ',';
          });
          text += row.sumStr + '\n';
        });
       
        var blob = new Blob([text],
                  { type: "text/plain;charset=utf-8" });
        saveAs(blob, "weights.csv");
      }
    },
    downloadlLast3yearsCorrelation() {
      if(this.last3YearCorrelationChartDataTable?.length > 0) {
        let text = '';
        this.last3yearsCorrelationChartOptions.xAxis.categories.forEach(indexName => {
          text += ',' + indexName ;
        });
        text += '\n';
        this.last3yearsCorrelationChartOptions.yAxis.categoriesReverse.forEach((indexName, y) => {
          text += indexName
          this.last3yearsCorrelationChartOptions.xAxis.categories.forEach((_, x) => {
            text += ',' + this.last3YearCorrelationChartDataTable[y][x];
          });
          text += '\n';
        });
       
        var blob = new Blob([text],
                  { type: "text/plain;charset=utf-8" });
        saveAs(blob, "Last3yearsCorrelation.csv");
      }
    },
    downloadlAllHistoryCorrelation() {
      if(this.allHistoryCorrelationChartDataTable?.length > 0) {
        let text = '';
        this.allHistoryCorrelationChartOptions.xAxis.categories.forEach(indexName => {
          text += ',' + indexName ;
        });
        text += '\n';
        this.allHistoryCorrelationChartOptions.yAxis.categoriesReverse.forEach((indexName, y) => {
          text += indexName
          this.allHistoryCorrelationChartOptions.xAxis.categories.forEach((_, x) => {
            text += ',' + this.allHistoryCorrelationChartDataTable[y][x];
          });
          text += '\n';
        });
       
        var blob = new Blob([text],
                  { type: "text/plain;charset=utf-8" });
        saveAs(blob, "AllHistoryCorrelation.csv");
      }
      
    },
    setEquityMetaDataCheckBox(param) {
      if (param.solutionParameterName.endsWith("Min") || param.solutionParameterName.endsWith("Max") || param.solutionParameterName.endsWith("Override")) {
        const paramValue = this.optimization.solution.values[param.solutionParameterId];  
        const title = param.solutionParameterTitle;
        if (paramValue != null) {
          this.equityMetaDataCheckBoxes[title] = true;
        }
      }
    },
    subClassContainsOverride(solutionParameters) {
      return solutionParameters.some(param => param.name.includes('Override'));
    },
    solutionParameterPairs(solutionParameters) {
      const pairs = {};
      solutionParameters.forEach((x) => {
        const type = x.name.split('_')[2];
        const key = x.title;
        if (!pairs[key]) {
          pairs[key] = {};
        }
        pairs[key][type] = x;
      });
      return pairs;
    },
    toggleAllSubClassParams(subClass) {
      const selected = subClass.allSelected;
      subClass.solutionParameters.forEach((param) => {
        this.$set(this.equityMetaDataCheckBoxes, param.title, selected);
      });
    },
    calculateSubClassCheckbox(subClass) {
      const paramsChecked = subClass.solutionParameters.reduce((sum, param) => {
        if (this.equityMetaDataCheckBoxes[param.title] == true) {
          sum += 1;
        }
        return sum;
      }, 0);

      if (paramsChecked === 0) {
        subClass.indeterminate = false
        subClass.allSelected = false
      } else if (paramsChecked === subClass.solutionParameters.length) {
        subClass.indeterminate = false
        subClass.allSelected = true
      } else {
        subClass.indeterminate = true
        subClass.allSelected = false
      }
    }
  }
};
</script>

<template>
  <Layout>
    <b-modal
      v-model="showSuscriptionEventsModal"
      title="Subscription events"
      title-class="text-dark font-18"
      hide-footer
    >
      <div class="mx-2">
      
        <ul class="list-group">
          <li class="list-group-item" v-for="ev in subscriptionEvents" :key="ev.id">
            <div class="d-flex justify-content-between">
              <b>
                <i class="fa fa-user ml-1"></i>
                {{ev.name || ev.userName || ev.userId}}
              </b>
              <b>
                <i class="fa fa-clock-o ml-1"></i>
                {{ev.dateTime | moment('L LT')}}
              </b>
            </div>
            
            <div class="mt-2 text-primary text-uppercase">
            
              {{ $t('subscription-event-type.' + ev.transaction.type) }}
            </div>
          </li>
        </ul>
        
        <div class="mt-4">
          <b-button 
            class="ml-1" 
            @click="showSuscriptionEventsModal = false"
          > 
            Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showSuscriptionModal"
      title="Subscribe"
      title-class="text-dark font-18"
      hide-footer
    >
      <form>
        
        <div class="form-group">
          <label>Amount</label>
          <input
            id="displayName"
            v-model.number="suscriptionData.amount"
            type="number"
            class="form-control"
          />
        </div> 

        <div class="form-group">
          <label>Currency</label>
          <select class="form-control" v-model="suscriptionData.currency">
            <option value="USD">USD</option>
            <option value="CNY">CNY</option>
          </select>
        </div> 
        
        <div>
          <b-button class="ml-1 float-left" 
          :disabled="subscribing"
          @click="showSuscriptionModal = false"> Cancel</b-button>
          <button type="button" class="btn btn-success float-right" :disabled="subscribing" @click="subscribe">
            <i class="fa fa-spin fa-spinner" v-if="subscribing"></i>
            Submit
          </button>
        </div>

      </form>
    </b-modal>
    <div class="">
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between pb-0">
            <div>   
              <h4 class="mb-0">
                <span class="title-content pr-2">
                  <span v-if="item" class="mr-2">
                    <a href="javascript:void(0)" v-if="!bookmarks[item.id]" @click="addBookmark(item.id)" ><i style="font-size:1.4rem" class="mdi mdi-star-outline text-muted "/></a>
                    <a href="javascript:void(0)" v-if="bookmarks[item.id]" @click="removeBookmark(item.id)"><i style="font-size:1.4rem" class="mdi mdi-star text-warning"/></a>
                  </span>
                  
                  {{ title }} 
                  
                  <span v-if="loading" class="mr-2"><i class="fa fa-spin fa-spinner"/></span>
                
                  <span v-if="item && item.optimization && item.optimization.calculation.status === 'PendingCalculation'" class="mr-2 badge badge-info">{{$t("pages.indexView.pending-calculating")}}</span>
                  <span v-if="item && item.optimization && item.optimization.calculation.status === 'CalculationInProcess'" class="mr-2 badge badge-primary">{{$t("pages.indexView.calculating")}}</span>

                  <span v-for="(tag, i) in tags" :key="i" class="mr-2 badge badge-light">
                    <i class="fa fa-tag mr-1"></i> {{tag}}
                  </span>
                </span>
              </h4>
              <span v-if="subscribed === true" class="badge badge-success mr-2">
                subscribed
              </span>
              <span class="badge badge-danger mr-2" v-if="item && item.deletedAt">Index was removed</span>
              
            </div>
        
            <div class="page-title-right d-none d-lg-block">
              <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
            </div>
          </div>
          <div class="font-size-16 pb-4 mt-1">
            <a v-if="item && !item.systemValues['WEBLINK']" href="http://www.c8-technologies.com" target="_blank">http://www.c8-technologies.com</a>
            <span v-if="item && item.systemValues['WEBLINK']" class="mr-1">Link to External Index Information:</span> <a v-if="item && item.systemValues['WEBLINK']" :href="item.systemValues['WEBLINK']" target="_blank">{{item.systemValues['WEBLINK']}}</a>
          </div>
         
        </div>
      </div>
      
      <div class="row" v-if="item && item.optimization && item.optimization.calculation.hasError">
        <div class="col">
          <div class="alert alert-danger">
            An error has occured. {{item.optimization.calculation.errorMessage}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="btn-toolbar mb-4">
            <div>
              
              <div class="btn-group mr-2 mb-2 mb-sm-0">
                <!-- <button type="button" class="btn btn-primary" @click="generateReport" :disabled="pdfProcessing || loading">
                  <i class="fa fa-spin fa-spinner" v-if="pdfProcessing"></i>
                  <i class="fa fa-file-pdf" v-if="!pdfProcessing"></i>
                </button> -->
                <button type="button" class="btn btn-primary" @click="generateReportV2" :disabled="pdfProcessing || !item" >
                  <i class="fa fa-spin fa-spinner" v-if="pdfProcessing"></i>
                  <i class="fa fa-file-pdf" v-if="!pdfProcessing"></i>
                </button>
              </div>
              <div class="btn-group mr-2 mb-2 mb-sm-0" v-if="item && item.isComposed" @click="clone" :disabled="cloning">
                 <button type="button" class="btn btn-secondary">
                  <i class="fa fa-clone mr-1" v-if="!cloning"></i>
                  <i class="fa fa-spin fa-spinner  mr-1" v-if="cloning"></i>
                  Clone
                </button>
              </div>
              <div class="btn-group mr-2 mb-2 mb-sm-0"  v-if="isAdmin || isC8Internal">
                <a  v-auth-href :href="getDailyNetReturnsUrl()" class="btn btn-secondary">
                  <i class="fa fa-download mr-2"></i> 
                  Daily Net Returns
                </a>
                <a  v-auth-href :href="getMonthlyNetReturnsUrl()" class="btn btn-secondary">
                <i class="fa fa-download mr-2"></i> 
                   Monthly Net Returns
                </a>
              </div>
             
              <div class="btn-group mr-2 mb-2 mb-sm-0" v-if="subscribed === true || subscribed === false">
                <button type="button" class="btn btn-primary" v-if="item && subscribed === false" @click="onSubscribe">
                  <i class="fas fa-plug  mr-1" v-if="!subscribing"></i>
                  <i class="fa fa-spin fa-spinner  mr-1" v-if="subscribing"></i>
                  Subscribe
                </button>
              </div>

              <div class="btn-group mr-2 mb-2 mb-sm-0" v-if="subscriptionEvents.length > 0">
                <button type="button" class="btn btn-primary" @click="showSuscriptionEventsModal = true">
                  <i class="fas fa-list-ul  mr-1"></i>
                  Show subscription events
                </button>
              </div>
              
              
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-right">
            <b-form-checkbox v-model="normalisedPerformance" switch class="mr-1" style="display:inline-block;">
            
            </b-form-checkbox>{{$t("pages.indexView.normalisedPerformance")}}
          </div>
        </div>
      </div>

      <!--  Stock Chart and statistics -->
      <div class="row">
        <div class="col-xl-8">
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                      <h5 class="card-title">{{$t('pages.indexView.indexPerformance-title')}}</h5>
                  </div>
                  <div class="col text-right">
                      <b-form-checkbox v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
                      style="display:inline-block;">
            
                      </b-form-checkbox>{{$t("pages.indexView.yAxisLogarithmic")}}
                  </div>
                </div>
                
                <h6 class="card-subtitle mb-2 mt-1 text-muted"><span v-html="$t('pages.indexView.indexPerformance-subtitle')"></span></h6>
                
                <highcharts 
                  v-if="isStatReady" 
                  :constructorType="'stockChart'" 
                  class="hc" 
                  :options="chartOptions" ref="chart">
                </highcharts> 
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-xl-4">
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.statistics-title')}}</h5>
                <div class="table-responsive" style="position: relative; " ref="statisticsDiv">
                  <span v-if="item && !(item.statistic || {}).firstTitle">No data found...</span>
                  <table v-if="item && (item.statistic || {}).firstTitle"
                    class="table table-centered table-stripped table-sm datatable table-hover dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th></th>
                        <th><b>{{item.statistic.firstTitle === 'C8' ? item.displayName : item.statistic.firstTitle}}</b></th>
                        <th v-if="item.statistic.hasComparisonValues"><b>{{item.statistic.secondTitle}}</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Annualised Return</td>
                        <td>{{((item.statistic || {}).values || {}).annualisedReturn}}%</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).annualisedReturn}}</td>
                      </tr>
                      <tr>
                        <td>Realised Volatility</td>
                        <td>{{((item.statistic || {}).values || {}).realisedVolatility}}%</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).realisedVolatility}}</td>
                      </tr>
                      <tr>
                        <td>Downside Volatility</td>
                        <td>{{((item.statistic || {}).values || {}).downsideVolatility}}%</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).downsideVolatility}}</td>
                      </tr>
                      <tr>
                        <td>Max Drawdown</td>
                        <td>{{((item.statistic || {}).values || {}).maxDrawdown}}%</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).maxDrawdown}}</td>
                      </tr>
                      <tr>
                        <td>Winning months</td>
                        <td>{{((item.statistic || {}).values || {}).winningMonths}}%</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).winningMonths}}</td>
                      </tr>
                      <tr>
                        <td>Sharpe Ratio</td>
                        <td>{{((item.statistic || {}).values || {}).sharpeRatio}}</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).sharpeRatio}}</td>
                      </tr>
                      <tr>
                        <td>Sortino Ratio</td>
                        <td>{{((item.statistic || {}).values || {}).sortinoRatio}}</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).sortinoRatio}}</td>
                      </tr>
                      <tr>
                        <td>Calmar Ratio</td>
                        <td>{{((item.statistic || {}).values || {}).calmarRatio}}</td>
                        <td v-if="item.statistic.hasComparisonValues">{{(item.statistic.comparisonValues || {}).calmarRatio}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <!--  Image -->
      <div class="row" >
        <div class="col-xl-12" style="" v-if="item && item.hasImage">
          <img v-if="item && item.hasImage" :src="getImageUrl()" class="mb-4 img-fluid rounded mx-auto d-block" >
        </div> 
      </div>  

      <!--  Index Composition -->
     
      <div class="row" v-if="item && item.isComposed">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.indexComposition-title')}}</h5>
                <div class="table-responsive">
                  <table
                    v-if="item && item.childrenIndexStatistic"
                    class="table table-centered table-hover  nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead >
                      <tr>
                        <th style="max-width:30px;width:30px; ">
                          <input type="checkbox" v-model="toggleIndexComposition"/>
                        </th>
                        <th></th>
                        <th>Annualised Return</th>
                        <th>Realised Volatility</th>
                        <th>Downside Volatility</th>
                        <th>Max Drawdown</th>
                        <th>Winning months</th>
                        <th>Sharpe Ratio</th>
                        <th>Sortino Ratio</th>
                        <th>Calmar Ratio</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="cIndex in item.composition" :key="cIndex.childId">
                        <td style="max-width:30px;width:30px; ">
                          <input type="checkbox" v-model="cIndex.chartVisible" @change="rebuildNetAssetValueGrowthCharts"/>
                        </td>
                        <td>
                          <strong style="white-space: initial;">
                            <a :href="'/indices/' + cIndex.childId + '/view'" class="text-dark">{{cIndex.childName}}</a>
                          </strong>
                       
                        </td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).annualisedReturn}}%</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).realisedVolatility}}%</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).downsideVolatility}}%</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).maxDrawdown}}%</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).winningMonths}}%</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).sharpeRatio}}</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).sortinoRatio}}</td>
                        <td>{{((item.childrenIndexStatistic[cIndex.childId] || {}).values || {}).calmarRatio}}</td>
                      </tr>
                    </tbody>  
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Montly performance -->
      <div class="row" v-if="monthlyPerfRowNames && monthlyPerfRowNames.length > 0">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body" >
                <h5 class="card-title">{{$t('pages.indexView.monthlyPerformance-title')}}</h5>
                <div class="table-responsive">
                  <table
                    class="table table-centered  table-hover nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead>
                      <tr>
                        <th style="max-width:60px;width:60px; "></th>
                        <th v-for="(cName, $index) in monthNames.filter(x => x !== 'YTD')" :key="$index" style="text-align: right;"><b>{{cName}}</b></th>
                        <th  style="max-width:100px;width:100px;text-align: right; ">{{$t("pages.indexView.monthlyPerformance-ytd")}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(rName, $rIndex) in monthlyPerfRowNames" :key=" $rIndex">
                        <td style="max-width:60px;width:60px; text-align: center;">{{rName}}</td>
                        <td v-for="(cName, $index) in monthNames.filter(x => x !== 'YTD')" :key="$index" style="text-align: right;">
                          <div v-if="item.liveMonthlyPerformanceYear && item.liveMonthlyPerformanceYear <= rName" style="color:#7d096d;font-family: sans-serif;font-weight: 700">
                            {{(monthlyPerf[rName][cName] || {}).base}}
                            <div :style="{'color': secondColor}" v-if="(monthlyPerf[rName][cName] || {}).additional">
                                {{(monthlyPerf[rName][cName] || {}).additional}}
                            </div>
                          </div>
                          <div v-if="!item.liveMonthlyPerformanceYear || item.liveMonthlyPerformanceYear > rName">
                            {{(monthlyPerf[rName][cName] || {}).base}}
                            <div :style="{'color': secondColor}" v-if="(monthlyPerf[rName][cName] || {}).additional">
                                {{(monthlyPerf[rName][cName] || {}).additional}}
                            </div>
                          </div>
                        </td>
                        <td style="max-width:100px;width:100px; text-align: right;">
                          {{(monthlyPerf[rName]['YTD'] || {}).base}}
                          <div :style="{'color': secondColor}" v-if="(monthlyPerf[rName]['YTD'] || {}).additional">
                              {{(monthlyPerf[rName]['YTD'] || {}).additional}}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table> 
                  <div v-if="item && item.monthlyPerformanceHint" class="text-muted mt-4"><span v-html="item.monthlyPerformanceHint"></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>   
      </div>  

      <!-- Weights -->
      <div class="row" v-if="item && item.compositionWeights && item.compositionWeights.length > 0">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.weights-title')}}</h5>
                <div class="row">
                  <div class="col-lg-7">
                     <highcharts 
                      v-if="isStatReady && weightsPiaChartOptions" 
                      class="hc" 
                      :options="weightsPiaChartOptions" ref="weightsPiaChart">
                    </highcharts> 
                  </div>
                  <div class="col-lg-5">
                     <highcharts 
                      v-if="isStatReady && volatilityScalingFactorChartOptions" 
                      class="hc mt-2 mb-2" 
                      :options="volatilityScalingFactorChartOptions" ref="volatilityScalingFactorChart">
                    </highcharts> 
                  </div>
                </div>
                
                <highcharts 
                  v-if="isStatReady && mostRecentWeightsChartOptions" 
                  class="hc mt-2 mb-2" 
                  :options="mostRecentWeightsChartOptions" ref="mostRecentWeightsChart">
                </highcharts> 
                
                <a class="mb-1" href="javascript:void(0)" @click="downloadWeights">[Download as CSV file]</a>
                <div class="table-responsive">
                  <table
                    class="table table-centered table-stripped table-hover nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead >
                      <tr>
                        <th style="max-width:80px;width:80px;text-align: right; "></th>
                        <th style="min-width:80px;text-align: right;" v-for="cIndex in item.composition" :key="cIndex.childId">{{cIndex.childDisplayName}}</th>
                        <th style="text-align: right;">VolatilityScalingFactor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, $index) in item.compositionWeightsObj.rows" :key="$index">
                        <td style="">{{row.value| moment("DD/MM/YYYY")}}</td>
                        <td v-for="cIndex in item.composition" :key="cIndex.childId" style="min-width:80px;text-align: right;">
                          <span style="width:50px;display: inline-block; text-align:right;">  {{(row.values[cIndex.childId] || {}).percValueStr || "0.0"}}%</span>
                        
                          &nbsp;({{(row.values[cIndex.childId] || {}).valueStr || "0.00"}})
                        </td>
                        <td style="text-align: right;">{{row.sumStr}}</td>
                      </tr>
                    </tbody>  
                  </table>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Last 3 Years correlation -->
      <div class="row" v-if="isStatReady && last3yearsCorrelationChartOptions">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.last3yearsCorrelation-title')}}</h5>
                <div class="row">
                  <div class="col-lg-12" v-if="!item.composition || item.composition.length < 20">
                    <highcharts 
                      class="hc" 
                      :options="last3yearsCorrelationChartOptions" 
                      :redrawOnUpdate="true"
                      ref="last3yearsCorrelationChart">
                    </highcharts> 
                  </div>
                  <div class="col-lg-12" v-else>
                    <a class="mb-1" href="javascript:void(0)" @click="downloadlLast3yearsCorrelation">[Download as CSV file]</a>
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tr v-for="(y, yi) in last3yearsCorrelationChartOptions.yAxis.categoriesReverse" :key="yi">
                          <td style="text-align: end; font-style: italic;max-width: 100px; width:100px; overflow: hidden; text-wrap: nowrap; text-overflow: ellipsis;" v-b-tooltip.hover="y" triggers="hover click">
                            {{ y }}
                          </td>
                          <template v-for="(x, xi) in last3yearsCorrelationChartOptions.xAxis.categories">
                          
                            <td :key="xi.toString() + '_2' + yi.toString()" class="table-cell-hightlighted">
                              <span  v-if="last3YearCorrelationChartDataTable[yi][xi]" v-b-tooltip.hover.html="getTooltipForLast3yearsCorrelation(x,y,last3YearCorrelationChartDataTable[yi][xi])" triggers="hover click">
                                {{ last3YearCorrelationChartDataTable[yi][xi] }}
                              </span>
                              
                            </td>
                          </template>
                        </tr>
                        <tr>
                          <td>
                            
                          </td>
                          <template v-for="(x, xi) in last3yearsCorrelationChartOptions.xAxis.categories">
                            <td :key="xi.toString() + '_4'  + '_b'" class="vertical-text" style="font-style: italic;">
                              {{ x }}
                            </td>
                          </template>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- All history correlation -->
      <div class="row" v-if="isStatReady && allHistoryCorrelationChartOptions">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.allHistoryCorrelation-title')}}</h5>
                <div class="row">
                  <div class="col-lg-12" v-if="!item.composition || item.composition.length < 20">
                    <highcharts 
                  
                      class="hc" 
                      :options="allHistoryCorrelationChartOptions" ref="allHistoryCorrelationChart">
                    </highcharts>
                  </div>
                  <div class="col-lg-12" v-else>
                    <a class="mb-1" href="javascript:void(0)" @click="downloadlAllHistoryCorrelation">[Download as CSV file]</a>
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tr v-for="(y, yi) in allHistoryCorrelationChartOptions.yAxis.categoriesReverse" :key="yi">
                          <td style="text-align: end; font-style: italic; max-width: 100px; width:100px; overflow: hidden; text-wrap: nowrap; text-overflow: ellipsis;" v-b-tooltip.hover="y" triggers="hover click">
                            {{ y }}
                          </td>
                          <template v-for="(x, xi) in allHistoryCorrelationChartOptions.xAxis.categories">
                          
                            <td :key="xi.toString() + '_2' + yi.toString()" class="table-cell-hightlighted">
                              <span  v-if="allHistoryCorrelationChartDataTable[yi][xi]" v-b-tooltip.hover.html="getTooltipForLast3yearsCorrelation(x,y,allHistoryCorrelationChartDataTable[yi][xi])" triggers="hover click">
                                {{ allHistoryCorrelationChartDataTable[yi][xi] }}
                              </span>
                              
                            </td>
                          </template>
                        </tr>
                        <tr>
                          <td>
                            
                          </td>
                          <template v-for="(x, xi) in allHistoryCorrelationChartOptions.xAxis.categories">
                            <td :key="xi.toString() + '_4'  + '_b'" class="vertical-text" style="font-style: italic;">
                              {{ x }}
                            </td>
                          </template>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Events -->
      <div class="row"   v-if="isStatReady && crisesChartOptions" >
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.crises-title')}}</h5>
                <div class="row">
                  <div class="col-lg-12">
                     <highcharts 
                    
                      class="hc" 
                      :options="crisesChartOptions" ref="crisesChart">
                    </highcharts>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Optimization -->
      <div class="row" v-if="item && item.optimization">
        <div class="col-xl-12" style="" >
          <div class="card cardc8" v-if="!editOptimizationMode">
            <div class="card-content">
              <div class="card-body" >
                <div class="row">
                  <div class="col-lg-6">
                    <h5 class="card-title mb-4">
                      {{$t('pages.indexView.optimization-title')}}
                      <span class="ml-2" v-if="item && item.allowEdit">
                        <button class="btn btn-link" @click="enableEditOptimizationMode">
                          <i class="fa fa-pen mr-1"/> 
                          <span>
                          {{$t('pages.indexView.editOptimization-title')}}
                          </span>
                        </button>
                      </span>
                    </h5>
                  </div>
                  <div class="col-lg-6 text-right">
                      <select class="form-control" v-model="selectedOptimizationHistory">
                        <option :value="null">Active</option>
                        <option v-for="h in optimizationHistory" :value="h" :key="h.id">
                          {{h.activatedAtFormatted}}
                        </option>
                      </select>
                  </div>
                </div>
                
                <optimization-view 
                  :model="selectedOptimizationHistory || item.optimization"
                  :composition="item.composition"
                >
                </optimization-view>
              </div>
            </div>
          </div>
          <div class="card cardc8"  v-if="editOptimizationMode">
            <div class="card-content" v-if="!editIndicesOfOptimizations">
              <div class="card-body" >
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                        <multiselect 
                          v-model="tags" 
                          placeholder="Tags"
                          :options="allTags" 
                          :taggable="true"
                          @tag="addTag"
                          :multiple="true">
                        </multiselect>
                      </div>
                    <h4 class="card-title">Portfolio Solution</h4>
                    
                    <select class="form-control" v-model="selectedPortfolioSolution" @change="portfolioSolutionChanged">
                      <option v-for="(sol, $index) in portfolioSolutions" :key="$index" :value="sol">{{sol.title}}</option>
                    </select>
                    <p class="alert alert-info mt-2" v-if="selectedPortfolioSolution" v-html="selectedPortfolioSolution.descriptionHtml"/>

                    <div v-if="selectedPortfolioSolution && selectedPortfolioSolution.name === 'MultiConstraint Equity Tactical Asset Allocation'">
                      <div class="mb-2" v-for="subClass in equityMetaData" :key="subClass.name">
                        <b-button block
                                v-b-toggle="'subclass-form-' + subClass.name"
                                variant="light"
                                class="d-flex justify-content-between align-items-center">
                          <span>{{ subClass.title }}</span>
                          <span>
                            <i :class="[{'fa-rotate-180': subClass.expanded}, 'fas fa-chevron-down']"></i>
                          </span>
                        </b-button>
                        <b-collapse :id="'subclass-form-' + subClass.name" v-model="subClass.expanded" class="mt-2">
                          <table class="table table-striped table-sm table-hover">
                            <thead>
                              <tr>
                                <th style="width:60px;max-width:60px;">
                                  <b-form-checkbox
                                    class="mx-2 my-1"
                                    plain
                                    v-model="subClass.allSelected"           
                                    :indeterminate="subClass.indeterminate"
                                    @change="toggleAllSubClassParams(subClass)"
                                  />
                                </th>
                                <th>{{ subClass.title }}</th>
                                <th>Min</th>
                                <th>Max</th>
                                <th v-if="subClassContainsOverride(subClass.solutionParameters)">Override</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(pair, solutionParameterName) in solutionParameterPairs(subClass.solutionParameters)" :key="solutionParameterName">
                                <td  style="width:60px;max-width:60px;">
                                  <input type="checkbox" class="m-2" v-model="equityMetaDataCheckBoxes[solutionParameterName]" @update="$forceUpdate()"/>
                                </td>
                                <td>
                                  <span class="mr-1">{{ solutionParameterName }}</span>
                                  <span v-if="paramIsPercentageNumberInput(pair.Min)">(%)</span>
                                </td>
                                <td v-for="(param, paramName) in { 'Min': pair.Min, 'Max': pair.Max, 'Override': pair.Override }" :key="paramName">
                                  <div class="input-group" v-if="paramName === 'Override' ? pair.Override !== undefined : true">
                                    <b-form-input 
                                      v-if="param.type !== 'Index'" 
                                      :type="param.type !== 'Index' ? 'number' : 'text'" 
                                      :disabled="!equityMetaDataCheckBoxes[solutionParameterName]"
                                      :style="{ opacity: !equityMetaDataCheckBoxes[solutionParameterName] ? '0.5' : '1' }"
                                      :formatter="formatPercentageInput"
                                      :step="(param.type !== 'Float' ? 1 : 0.1)" 
                                      :min="(param.min || 0) * 100" 
                                      :max="(param.max || 1) * 100"
                                      :value="formatToPercentage(optimization.solution.values[param.id])"
                                      @update="$forceUpdate()"
                                      @change="(value) => setValueFromPercentage(param.id, value)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-collapse>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div class="form-group" v-for="p in solutionParametersNoEquityMetaData" :key="p.id">
                        <div>  
                          <label class="mr-2">
                            {{p.title}}
                            <span v-if="paramIsPercentageNumberInput(p)">
                              (%)
                            </span>
                          </label>
                          <span v-b-tooltip.hover.html="getSolutionParameterTooltip(p.name)" triggers="hover click">
                            <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                          </span>
                        </div>
                        <div>
                          <b-form-input
                            v-if="paramIsNumberInput(p)"
                            type="number" 
                            v-model.number="optimization.solution.values[p.id]" 
                            :min="p.min" 
                            :max="p.max" 
                            :step="(p.type !== 'Float' ? 1 : (p.decimals === 1 ? 0.1 : 0.01))"
                          />
                          <b-form-input
                            v-if="p.name === 'MVOLambda'" 
                            type="number" 
                            v-model.number="optimization.solution.values[p.id]" 
                            :min="p.min" 
                            :max="1e6" 
                            :step="0.1"
                          />
                          <b-form-input
                            v-if="paramIsPercentageNumberInput(p)"
                            type="number" 
                            :formatter="formatPercentageInput"
                            :step="(p.type !== 'Float' ? 1 : 0.1)" 
                            :min="(p.min || 0) * 100" 
                            :max="(p.max || 1) * 100"
                            :value="formatToPercentage(optimization.solution.values[p.id])"
                            @change="(value) => setValueFromPercentage(p.id, value)"
                          />

                          <b-form-input 
                            v-if="p.type === 'Index'"
                            type="text" 
                            v-model.number="optimization.solution.values[p.id]" 
                          />
                        </div>
                      </div>
                    </div>

                    <h4 class="card-title mt-4">
                      <input type="checkbox" class="mr-2" v-model="optimization.walkForwardOptimizationEnabled"/> 
                      <span class="mr-2">  
                        Walk Forward Optimisation
                      </span>
                      <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.walkForwardOptimizationTooltip')" triggers="hover click">
                        <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                      </span>
                    </h4>
              
                    <div class="form-group" v-if="optimization.walkForwardOptimization">
                      <div>
                        <label class="mr-2">
                          In Sample Period (Months)
                        </label>
                        <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.walkForwardOptimization_inSamplePeriodTooltip')" triggers="hover click">
                          <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                        </span>
                      </div>
                      <div>
                        <b-form-input 
                          type="number" 
                          :disabled="!optimization.walkForwardOptimizationEnabled"
                          v-model.number="optimization.walkForwardOptimization.inSamplePeriod" 
                          :step="1"
                        />
                      </div>

                      <div> 
                        <label class="mr-2">
                          Period between Rebalancing (Months)
                        </label>
                        <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.walkForwardOptimization_periodBetweenRebalancingTooltip')" triggers="hover click">
                          <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                        </span>
                      </div>
                      <div>
                        <b-form-input 
                          type="number" 
                          :disabled="!optimization.walkForwardOptimizationEnabled"
                          v-model.number="optimization.walkForwardOptimization.periodBetweenRebalancing" 
                          :step="1"
                        />
                      </div>
                    </div>

                    <h4 class="card-title mt-4 ">
                      <input type="checkbox" class="mr-2" v-model="optimization.targetVolatilityEnabled"/> 
                      <span class="mr-2">
                        Target Volatility (%):
                      </span>
                      <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.targetVolatilityTooltip')" triggers="hover click">
                        <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                      </span>
                    </h4>
                    <div class="form-group">
                      <div>
                        <b-form-input 
                          type="number" 
                          :disabled="!optimization.targetVolatilityEnabled"
                          v-model.number="optimization.targetVolatility" 
                          :min="0"
                          :max="100"
                          :step="1"
                        />
                      </div>
                    </div>
                
                    <h4 class="card-title mt-4 ">
                      Optimization date
                    </h4>
                    <div class="form-group">
                      <div>
                        <label class="mr-2">
                          Start
                        </label>
                        <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.startDateTooltip')" triggers="hover click">
                          <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                        </span>
                      </div>
                      <div>
                        <b-form-datepicker 
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          today-button
                          reset-button
                          close-button
                          v-model="optimization.startDate" 
                          class="mb-2">
                        </b-form-datepicker>
                      </div>
                    </div>

                    <div class="form-group">
                      <div>
                        <label class="mr-2">
                          End
                        </label>
                        <span v-b-tooltip.hover.html="$t('pages.indexView.optimization.endDateTooltip')" triggers="hover click">
                          <i class="mdi mdi-information-variant" style="background-color:#eff2f7;border-radius:50%"/>
                        </span>
                      </div>
                      <div>
                        <b-form-datepicker 
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          today-button
                          reset-button
                          close-button
                          v-model="optimization.endDate" class="mb-2">
                        </b-form-datepicker>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-8">
                    <h4 class="card-title">Selected indices</h4>
                    
                    <hr/>
                    <table class="table table-striped table-sm table-hover">
                      <thead >
                        <tr>
                          <th  style="width:60px;max-width:60px;">
                            <input type="checkbox" class="m-2" v-model="checkedEditedComposition" />
                          </th>
                          <th class="">Index name</th>
                        
                          <th v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                            {{p.title}}
                          </th>
                          <th style="max-width:100px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, $ccnIdex) in item.editedComposition" :key="$ccnIdex">
                          <td  style="width:60px;max-width:60px;">
                            <input type="checkbox" class="m-2" v-model="i.checked"/>
                          </td>
                          <td class="">
                            {{i.childDisplayName}}
                            <small v-if="i.minDate" class="d-block">
                              <span class="font-bold">Start Date: {{i.minDate | moment('LL')}}</span>
                            </small>
                          </td>
                          <td v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                            <div class="input-group">
                              <div class="input-group-append">
                                <div class="btn-group">
                                  <button type="button" class="btn btn-secondary btn-sm px-3"
                                    @click="toggleInputParamtersForm(($ccnIdex + '+' + p.id))"
                                  >
                                    <i class="fa fa-copy "></i>
                                  </button>
                                  <div 
                                    class="dropdown-menu dropdown-menu-end border show" 
                                    v-click-outside="inputParametersFormOutsideClickConfig"
                                    v-if="inputParamtersFormId === ($ccnIdex + '+' + p.id)"
                                  >
                                    <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;" 
                                      @click="copyParameterToSelected($ccnIdex, i.childId, p)"
                                    >
                                      Clone to selected
                                    </a>
                                    <a href="javascript:void(0)" class="dropdown-item" style="cursor: pointer;"
                                      @click="copyParameterToAll($ccnIdex, i.childId, p)"
                                    >
                                      Clone to all
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <b-form-input 
                                v-if="p.type !== 'Index'"
                                :formatter="formatPercentageInput"
                                :step="0.1" 
                                :min="(p.min || 0) * 100" 
                                :max="(p.max || 1) * 100"
                                :value="formatToPercentage(optimization.solution.values[p.id + i.childId])"
                                @change="(value) => setValueFromPercentage(p.id + i.childId, value)"
                              />
                              <b-form-input 
                                v-if="p.type === 'Index'"
                                type="text" 
                                v-model.number="optimization.solution.values[p.id + i.childId]" 
                              />
                            </div>
                          </td>
                          <td>
                            <button class="btn  btn-secondary" @click="removeEditedIndex(i)">
                              Remove
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button class="btn btn-sm btn-outline-secondary float-right" @click="showEditIndicesOfOptimizations">Add Indicies</button>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col text-left">
                    <button type="button" class="btn btn-secondary float-left" @click="disableEditOptimizationMode">
                      {{$t("pages.indexView.disableEditOptimizationMode-title")}}
                    </button>
                  </div>
                  <div class="col text-right">
                    <button type="button" class="btn btn-primary float-right" @click="submitOptimization">
                      <i class="fa fa-save mr-2" v-if="!savingOptimization"></i> 
                      <i class="fa fa-spin fa-spinner mr-2" v-if="savingOptimization"></i> 
                      {{$t("pages.indexView.saveOptimization-title")}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content" v-if="editIndicesOfOptimizations">
              <index-selector 
                @cancel="editIndicesOfOptimizations = false"
                @apply="addIndices"
              >
                
              </index-selector>
            </div>
          </div>
        </div>
      </div>

      <!-- Links -->
      <div class="row" v-if="item && item.links && item.links.length > 0">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.link-title')}}</h5>
                <div class="row">
                  <div class="col-lg-12">
                    <ul>
                      <li v-for="(l, $index) in item.links" :key="$index">
                        <a :href="l.link">{{l.title}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-4">
        <span v-if="item" class="text-muted ml-2">Updated at: {{item.updatedAtFormatted}}</span>
      </div>
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-link mt-4 mb-4 float-left" @click="openIndicesPage">
              <i class="fa fa-angle-double-left mr-2"></i> {{$t("pages.indexView.back")}}
          </button>
          <button 
            v-if="item && item.createdByUserId && item.allowDelete"
            type="button" 
            class="btn btn-outline-danger mt-4 mb-4 float-right" 
            @click="onDelete"
          >
              <i v-if="!deleting" class="fa fa-times mr-2"></i>
              <i v-if="deleting" class="fa fa-spin fa-spinner"></i>
              Delete
          </button>
        </div>
      </div>
     
    </div>
  </Layout>
</template>